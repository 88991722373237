import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
    getCourts: createCancelTokenHandler('getCourts'),
    getCourtsTrash: createCancelTokenHandler('getCourtsTrash'),
    getCourtActivitiesLog: createCancelTokenHandler('getCourtActivitiesLog')
}


function makeQuery (page = 1, filters = [], sorts = []) {
    let queries = []

    if (page > 1) queries.push(`page=${page}`)

    if (filters.length) queries = queries.concat(...filters)

    if (sorts.length) queries = queries.concat(...sorts)

    return queries
}

export function getCourts (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/club/courts${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCourts'].handleRequestCancellation().token
    })
}

export function getCourt (id) {
    return axios({
        url: `v1/admin/club/courts/${id}`,
        method: 'get'
    })
}

export function getCourtActivitiesLog (id, page, filters = [], sorts = []) {
    let queries = makeQuery(page, filters, sorts)

    if (queries.length > 0) queries = `?${  queries.join('&')}`
    return axios({
        url: `v1/admin/club/courts/${id}/activity-logs${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCourtActivitiesLog'].handleRequestCancellation().token
    })
}

export function insertCourt (payment) {
    return axios({
        url: 'v1/admin/club/courts',
        data: payment,
        method: 'post'
    })
}

export function editCourt (id, payment) {
    return axios.patch(`v1/admin/club/courts/${id}`, payment)
}

export function deleteCourt (id, force = false) {
    return axios({
        url: `v1/admin/club/courts/${id}${force ? '/force' : ''}`,
        method: 'delete'
    })
}

export function restoreCourt (id) {
    return axios({
        url: `v1/admin/club/courts/${id}/restore`,
        method: 'put'
    })
}

export function getCourtsTrash (page = 1, filters = [], sorts = []) {

    let queries = makeQuery(page, filters, sorts)

    queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

    return axios({
        url: `v1/admin/club/courts?trashed=true${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCourtsTrash'].handleRequestCancellation().token
    })
}
